import React from "react"

import { COLORS, BORDER_RADIUS, GRADIENT } from "../styles/constants"
import "../styles/button.css"

const ButtonSecondary = ({ children }) => (
  <button
    style={{
      padding: ".5rem 2.0rem",
      color: COLORS.blue,
      fontWeight: 700,
      background: COLORS.white,
      borderRadius: BORDER_RADIUS,
      borderWidth: 1,
      borderColor:COLORS.gray,
      cursor: "pointer",
    }}
  >
    {children}
  </button>
)

export default ButtonSecondary
