import React from "react"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { Reveal , Animation} from "react-genie"

const Point = (props) => (
  <div className="point" style={{ 
      padding: "4rem 0 0 0",
      backgroundColor: "#f2f2f2"
    }}>
      <div className="point-container">
        <div style={{display:"flex", justifyContent:"space-around", flexWrap:"wrap"}}>
          <Reveal animation={Animation.FadeIn} delay={500}>
            <div style={{padding:"1rem"}}>
              <img src={props.imgsrc} alt="特徴"/>
            </div>
          </Reveal>
          <Reveal animation={Animation.FadeIn} style={{padding:"1rem"}}>
            <div className="point-description">
              <h3><FontAwesomeIcon icon={props.pointicon} style={{fontSize:"2rem",marginRight:15}}/>{props.pointTitle}</h3>  
              <ul>
              {
                props.description.map((x, ix) => {
                  return <li key={ix} style={{listStyle:"none", margin:"1rem 0 1rem 3rem"}}><FontAwesomeIcon icon={faCheck} style={{marginRight:15, color:"skyblue"}}/>{x}</li>;
                })
              }
              </ul>
            </div>
          </Reveal>
        </div>
      </div>
  </div>
)

export default Point
