import React from "react"

import PriceTable from "../components/price-table"
import SectionHeader from "./section-header"
import Button from "../components/button"

import cta_example from "../images/cta_example.png"

const priceHeaderStyle = {
  alignItems: "center",
  minHeight: 90,
  textAlign: "center",
  fontSize: "1rem",
}

const priceHeaderStrongStyle = {
  fontSize: "2rem",
}

const priceTableGeneralStyle = `ul.features li:nth-child(odd){background-color: #fdfdfd;}`;

const CallToAction = () => (
  <div className="cta" style={{ padding: "4rem 1rem", textAlign: "center" }}>
    <div className="cta-container">
      <SectionHeader
        title="選べるプラン 無料でも"
        description="App StoreでiPhone、iPadアプリをダウンロードするだけで無料で開始できます。"
      />
      <div style={{marginBottom:40}}>
        <h3>アプリをインストールするだけで以下の機能がご利用いただけます。</h3>
        <div style={{display:"flex", flexWrap:"wrap", justifyContent:"center"}}>
          <style>
            {priceTableGeneralStyle}
          </style>
          <PriceTable 
            title="フリープラン" 
            description="アプリをインストールするだけで利用可能！"
            activationKeys = "-"
            itemLimit = "無制限"
            canTotalIventory = {false}
            canImportMaster = {false}
            canSaveCloud = {false}
            canUseItemInputService = {false}
            >
            <header style={priceHeaderStyle}>
              <p>無料<br />
                <strong style={priceHeaderStrongStyle}>0<span style={{fontSize:"1rem"}}>円</span></strong>
              </p>
            </header>
          </PriceTable>
        </div>
      </div>
      <div style={{marginBottom:40}}>
        <h3>さらに有料会員登録いただくことで複数端末で実施した棚卸データをまとめたり、複数端末で共通の商品データを適用いただけます。</h3>
        <div style={{display:"flex", flexWrap:"wrap", justifyContent:"center"}}>
          <PriceTable 
            title="有料プラン" 
            description="(スマホ1台につき)"
            activationKeys = "1台"
            itemLimit = "無制限"
            canImportMaster = {true}
            canSaveCloud = {true}
            canTotalIventory = {true}
            canUseItemInputService = {true}
            >
            <header style={priceHeaderStyle}>
              <p>6ヶ月<br />
                <strong style={priceHeaderStrongStyle}><span style={{color:"black"}}>550</span><span style={{fontSize:"1rem"}}>円</span></strong><br/>
                (通常価格/税込)
              </p>
            </header>
          </PriceTable>
        </div>

        <p style={{marginTop:"1rem", marginBottom:"5rem"}}>
          年間 1,100円の投資で、棚卸の負担が劇的に減るシステムは、これしかないと自信をもっておすすめします！
        </p>
        
        <div className="cta_example">
          <h4>契約期間と契約更新および追加端末について</h4>
          <h5>契約更新の期間と課金および複数端末の契約例</h5>
          <div className="centerImg">
            <img src={cta_example} alt="課金例"/>
          </div>
          <ul style={{margin:"1rem 3rem 5rem 3rem"}}>
            <li>端末認証キーの決済は、契約期間に対して前払いとなります。</li>
            <li>契約更新の60日前までに購入した分は、次回契約更新の際に決済はされません。（契約更新の際の決済からは差し引かれます。)</li>
            <li>同一アカウント内で端末を追加契約すると、その時点から使用可能になります。</li>
            <li>アカウントごとでの契約となります。</li>
            <li>契約の更新は60日前から可能です。</li>
          </ul>
        </div>
      </div>


      <a href="https://apps.apple.com/app/id1509177299/" target="_blank" style={{ color: "white"}}>
        <Button>
          無料ではじめる
        </Button>
      </a>
      <a href="https://tanaoroshi.barpro.co.jp/" target="_blank" style={{ color: "white"}}>
        <Button>
          有料会員になる
        </Button>
      </a>
    </div>
  </div>
)

export default CallToAction
