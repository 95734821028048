import React from "react"

import Button from "./button"
import SectionHeader from "./section-header"
import { COLORS } from "../styles/constants"

const priceTableDivStyle = {
  position: "relative",
  width: "100%",
  maxWidth: 200,
  marginLeft: -1,
  border: "1px solid #e4e4e4",
  background: "#f3f3f3",
  boxSizing: "border-box",
  zIndex: 0,
  display: "flex",
  flexDirection: "column",
}

const priceTitleStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-end",
  height: 55,
  paddingBottom: 10,
  color: "#fff",
  background: "#5881BF",
  fontSize: "1rem",
  textDecoration: "none",
  boxSizing: "border-box",
  margin: 0,
};

const priceDescriptionStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  flex: "auto",
  // minHeight: 274,
  padding: "20px 0",
  boxSizing: "border-box",
};

const priceDescriptionHeaderStyle = {
  alignItems: "center",
  minHeight: 90,
  textAlign: "center",
  fontSize: "0.75rem",
}

const priceDescriptionParagraphStyle = {
  flex: "auto",
  maxWidth: "100%",
  padding: "0 10px",
  fontSize: "0.75rem",
  lineHeight: "0.75rem",
  boxSizing: "border-box",
}

const priceTableListItemStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 36,
  fontSize: "0.75rem",
  margin: 0
}

const priceTableListItemStrongStyle = {
  marginLeft: 5,
  fontSize: 14,
  fontWeight: "bold",
}

const PriceTable = (props) => (
  <div style={priceTableDivStyle}>
    <h2><a style={priceTitleStyle}>{props.title}</a></h2>
    <div style={priceDescriptionStyle}>
      <header style={priceDescriptionHeaderStyle}>
        {props.children}
      </header>
      <p style={priceDescriptionParagraphStyle}>{props.description}</p>
    </div>
    <ul className="features" style={{listStyle: "none"}}>
      {/*props.activationKeys != "-" ? <li style={priceTableListItemStyle}>入力端末台数<strong style={priceTableListItemStrongStyle}>{props.activationKeys}</strong></li> : null*/}
      <li style={priceTableListItemStyle}>商品数制限<strong style={priceTableListItemStrongStyle}>{props.itemLimit}</strong></li>
      <li style={priceTableListItemStyle}>棚卸データCSV出力<strong style={priceTableListItemStrongStyle}>○</strong></li>
      {props.activationKeys != "-" ?  <li style={priceTableListItemStyle}>商品情報CSV取り込み<strong style={priceTableListItemStrongStyle}>{props.canImportMaster ? "○" : "×"}</strong></li> : null}
      {props.activationKeys != "-" ?  <li style={priceTableListItemStyle}>棚卸データクラウド保存<strong style={priceTableListItemStrongStyle}>{props.canSaveCloud ? "○" : "×"}</strong></li> : null}
      {props.activationKeys != "-" ?  <li style={priceTableListItemStyle}>複数端末棚卸合算<strong style={priceTableListItemStrongStyle}>{props.canTotalIventory ? "○" : "×"}</strong></li> : null}
      {props.activationKeys != "-" ?  <li style={priceTableListItemStyle}>機種変によるデータ保存<strong style={priceTableListItemStrongStyle}>{props.canSaveCloud ? "○" : "×"}</strong></li> : null}
      {/*props.activationKeys != "-" ?  <li style={priceTableListItemStyle}>商品登録サービス（有料）<strong style={priceTableListItemStrongStyle}>{props.canUseItemInputService ? "○" : "×"}</strong></li> : null*/}
    </ul>
    {/* <footer><a href="#dummy">詳しくみる</a></footer> */}
  </div>
)

export default PriceTable
