import React from "react"

import SectionHeader from "./section-header"
import Button from "../components/button"
import ButtonSecondary from "../components/button-secondary"
import { Reveal , Animation} from "react-genie"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import {Link} from "gatsby"

const Question = (props) => (
  <div className="question" style={{ padding: "4rem 1rem", textAlign: "center", backgroundColor: "#f2f2f2" }}>
    <div className="question-container">
      <SectionHeader
        title="よくある質問"
        description=""
      />
      <div className="question-main" style={{display:"flex", justifyContent:"center", flexWrap:"wrap", margin:"0 auto"}}>
        <Reveal animation={Animation.SlideInLeft}>
          <div style={{padding:"1rem"}}>
            <h3 style={{textAlign:"left"}}><FontAwesomeIcon icon={faQuestionCircle} style={{fontSize:"2rem",marginRight:15}}/><span>無料で使えますか？</span></h3>  
            <p style={{border:"1px solid #555", borderRadius:"0.5rem", padding: "2rem", textAlign:"left"}}>はい。無料版もございます。（有料版より機能は制限されます）</p>
          </div>
        </Reveal>
        <Reveal animation={Animation.SlideInLeft}>
          <div style={{padding:"1rem"}}>
            <h3 style={{textAlign:"left"}}><FontAwesomeIcon icon={faQuestionCircle} style={{fontSize:"2rem",marginRight:15}}/><span>どんなスマホでも使えますか？</span></h3>  
            <p style={{border:"1px solid #555", borderRadius:"0.5rem", padding: "2rem", textAlign:"left"}}>今はiPhone、iPadのみで使えます。Android版は計画中です。</p>
          </div>
        </Reveal>
        <Reveal animation={Animation.SlideInLeft}>
          <div style={{padding:"1rem"}}>
            <h3 style={{textAlign:"left"}}><FontAwesomeIcon icon={faQuestionCircle} style={{fontSize:"2rem",marginRight:15}}/><span>どのくらい棚卸が楽になりますか？</span></h3>  
            <p style={{border:"1px solid #555", borderRadius:"0.5rem", padding: "2rem", textAlign:"left"}}>某社様は、検算を含めると、かかる時間は半分以下（約65%削減）、労力は三分の一になったそうです。業種や規模によって差はありますが、逆に大変になった、というお話がお客様からきたことはありません。</p>
          </div>
        </Reveal>
      </div>

    </div>
    <div class="three-buttons" style={{justifyContent:"space-around", maxWidth:750, margin:"0 auto"}}>
        <Reveal delay="100" style={{margin:10}}>
          <Link
            to="/qa"
            style={{
              color: `#333`,
              textDecoration: `none`,
            }}
          >
            <ButtonSecondary>
              上にない質問はこちらから
            </ButtonSecondary>
          </Link>
        </Reveal>
        <Reveal delay="100" style={{margin:10}}>
          <a href="https://apps.apple.com/app/id1509177299/" target="_blank" style={{ color: "white" }}>
            <Button>
              無料ではじめる
            </Button>
          </a>
        </Reveal>
        <Reveal  delay="100" style={{margin:10}}>
            <a href="https://tanaoroshi.barpro.co.jp/" target="_blank" style={{ color: "white"}}>
              <Button>
                有料会員になる
              </Button>
            </a>
        </Reveal>
      </div>
  </div>
)

export default Question
