import React from "react"
import Button from "../components/button"
import ButtonSecondary from "../components/button-secondary"

import {Link} from "gatsby"
import { Reveal , Animation} from "react-genie"

const ButtonStartOnly = (props) => (
  <div className="about" style={{ 
      padding: "4rem 0 0.5rem 0",
      textAlign: "center",
      backgroundColor: "#f2f2f2"
    }}>
    {/* <div style={{display:"flex", justifyContent:"center", flexWrap:"wrap", marginBottom: 80}}> */}
    <div className="three-buttons" style={{ justifyContent:"space-around", maxWidth:650, margin:"0 auto 80px"}}>
      {props.extensionhref?.length ?
        <Reveal delay="100" style={{margin:10}}>
          <Link
            to={props.extensionhref}
            style={{
              color: `#333`,
              textDecoration: `none`,
            }}
          >
            <ButtonSecondary>
              {props.extensionbtntitle ? props.extensionbtntitle : "もっと見る"}
            </ButtonSecondary>
          </Link>
        </Reveal>
        : null
      }
      <Reveal delay="100" style={{margin:10}}>
        <a href="https://apps.apple.com/app/id1509177299/" target="_blank" style={{ color: "white" }}>
          <Button>
            無料ではじめる
          </Button>
        </a>
      </Reveal>
      <Reveal delay="100" style={{margin:10}}>
        <a href="https://tanaoroshi.barpro.co.jp/" target="_blank" style={{ color: "white" }}>
            <Button>
              有料会員になる
            </Button>
        </a>
      </Reveal>
    </div>
  </div>
)

export default ButtonStartOnly
