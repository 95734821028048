import React from "react"

import janimg from "../images/jan_read.png"
import SectionHeader from "./section-header"
import { COLORS } from "../styles/constants"
import featureicon1 from "../images/feature-icon01.png"
import featureicon2 from "../images/feature-icon02.png"
import featureicon3 from "../images/feature-icon03.png"
import featureicon4 from "../images/feature-icon04.png"

import { Reveal , Animation} from "react-genie"

const Feature = (props) => (
  <div className="feature" style={{ 
      padding: "4rem 0 0 0",
      // backgroundColor: "#f2f2f2",
      textAlign:"center"
    }}>
    <div className="feature-container">
      <SectionHeader
          title="様々な機能"
          description="棚卸で欲しかった機能を詰め込みました"
        />
      <ul style={{display:"flex", flexWrap:"wrap", listStyle: "none"}}>
        <li>
          <Reveal style={{display:"flex"}}>
            <div style={{width: 121, flexShrink: 0, padding: 0}}>
              <img style={{width:"100%"}} src={featureicon1} alt="バーコード" />
            </div>
            <div style={{width:"auto", paddingLeft:24, paddingRight:24, boxSizing:"border-box"}}>
              <h3 style={{marginBottom:"0.5rem", textAlign:"left"}}>バーコードで実地棚卸</h3>
              <p style={{textAlign:"left"}}>スマホアプリを起動して商品のJANコードを読み取るだけで棚卸が可能。</p>
            </div>
          </Reveal>
        </li>
        <li>
          <Reveal style={{display:"flex"}}>
            <div style={{width: 121, flexShrink: 0, padding: 0}}>
              <img style={{width:"100%"}} src={featureicon2} alt="棚卸表" />
            </div>
            <div style={{width:"auto", paddingLeft:24, paddingRight:24, boxSizing:"border-box"}}>
              <h3 style={{marginBottom:"0.5rem", textAlign:"left"}}>棚卸表もらくらく</h3>
              <p style={{textAlign:"left"}}>スマホで実施した棚卸データから棚卸表を簡単作成。</p>
            </div>
          </Reveal>
        </li>
        <li>
          <Reveal style={{display:"flex"}}>
            <div style={{width: 121, flexShrink: 0, padding: 0}}>
              <img style={{width:"100%"}} src={featureicon3} alt="商品情報" />
            </div>
            <div style={{width:"auto", paddingLeft:24, paddingRight:24, boxSizing:"border-box"}}>
              <h3 style={{marginBottom:"0.5rem", textAlign:"left"}}>商品情報の管理</h3>
              <p style={{textAlign:"left"}}>店舗で扱っている商品情報を管理。棚卸の手間が大幅に軽減。</p>
            </div>
          </Reveal>
        </li>
        <li>
          <Reveal style={{display:"flex"}}>
            <div style={{width: 121, flexShrink: 0, padding: 0}}>
              <img style={{width:"100%"}} src={featureicon4} alt="csv" />
            </div>
            <div style={{width:"auto", paddingLeft:24, paddingRight:24, boxSizing:"border-box"}}>
              <h3 style={{marginBottom:"0.5rem", textAlign:"left"}}>棚卸データをCSV出力</h3>
              <p style={{textAlign:"left"}}>棚卸データをCSVで出力することが可能。</p>
            </div>
          </Reveal>
        </li>
      </ul>
    </div>
  </div>
)

export default Feature
