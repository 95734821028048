import React from "react"

import SectionHeader from "./section-header"
import Button from "../components/button"
import { Reveal , Animation} from "react-genie"
import airbuggyImg1 from "../images/yoyogi_01.jpg"
import airbuggyImg2 from "../images/yoyogi_03.jpg"
import carrotImg1 from "../images/carrot01.jpg"
import carrotImg2 from "../images/carrot02.jpg"
import gwasendoImg1 from "../images/gwasendo01.jpg"
import gwasendoImg2 from "../images/gwasendo02.jpg"

const CustomerVoice = (props) => (
  <div className="customer-voice" style={{ padding: "4rem 1rem", textAlign: "center", background:"#f2f2f2" }}>
    <div className="customer-voice-container">
      <SectionHeader
        title="お客様の声をご紹介"
        description=""
      />
      <div style={{display:"flex", justifyContent:"space-around", flexWrap:"wrap"}}>
        <Reveal animation={Animation.FadeIn} delay={500}>
          <div style={{padding:"1rem", display: "flex", flexDirection: "column"}}>
            <img style={{margin:"1rem"}} src={airbuggyImg1} alt="AIRBUGGY"/>
            <img style={{margin:"1rem"}} src={airbuggyImg2} alt="AIRBUGGY"/>
            {/* <img src="https://via.placeholder.com/480x270" /> */}
          </div>
        </Reveal>
        <Reveal animation={Animation.FadeIn} style={{padding:"1rem"}}>
          <div className="customer-voice-description">
            <h4>AIRBUGGY</h4>
            <h5>株式会社 GMPインターナショナル</h5>
            <p style={{maxWidth:410, textAlign:"left"}}>
              メーカーなので組み立て部品などがあり、レジのソフトでは棚卸はできず、以前は棚卸を全て手書きで行っておりました。<br/>
              ある展示会でこの棚卸専用のアプリの存在を知りました。「働き方改革」が最近のキーワードにもなってきていることもあり、
              棚卸＝大変＝残業というのを少しでも軽減できるのではと思い、一度使ってみることにしました。<br/>
              実際使ってみると、必要な機能を全て備えているのに、無駄な機能がないのでわかりやすく、本当に使いやすいです。<br/>
              データを残せるしスマホを何台も連動できてまとめる事ができるので楽になりました。おまけに価格が大変安いので大助かりです。<br/>
              棚卸の時間が大幅に軽減されましたし、何より検算の時間が全く必要なくなりました。<br/>
              今までは横計・縦計の繰り返しでとても時間がかかっていたのですが、その時間がほぼ無くなりました。<br/>
              しかも計算を間違うこともありません。<br/>
              CSVデータとしてダウンロードできるのも便利です。これからもっとスマホの台数を増やして、さらに効率UPしていくつもりです。</p>
          </div>
        </Reveal>
      </div>
      <div style={{display:"flex", justifyContent:"space-around", flexWrap:"wrap"}}>
        <Reveal animation={Animation.FadeIn} delay={500}>
          <div style={{padding:"1rem", display: "flex", flexDirection: "column"}}>
            <img style={{margin:"1rem"}} src={carrotImg1} alt="キャロット"/>
            <img style={{margin:"1rem"}} src={carrotImg2} alt="キャロット"/>
          </div>
        </Reveal>
        <Reveal animation={Animation.FadeIn} style={{padding:"1rem"}}>
          <div className="customer-voice-description">
            <h4>ナチュラルライフ ステーション キャロット</h4>
            <h5>株式会社 大王商会</h5>
            <p style={{maxWidth:410, textAlign:"left"}}>
              当社は約1万点の扱い商品があり、倉庫と直営店舗に陳列されています。<br/>
              食料品から化粧品まで取り扱っており、卸しもしているので在庫はかなりの数と金額になります。<br/>
              棚卸しにおいて実棚アプリを使用するまでは、当然手書きで従業員全員で書き出しに丸一日かかっており、それの検算で何度も縦計、横計を繰り返し完全に終了するまで1ヶ月ほど要していました。
              それが実棚アプリを使う事で棚卸し時間は6分1に短縮でき、検算は数分で終了するのでびっくりしました。<br/>
              しかも間違いがありません。<br/><br/>
              専用バーコードリーダーを導入した事で更に時間短縮出来ています。<br/><br/>
              あれだけ大変だった棚卸しが一気に楽になりましたし、働き方改革の一つの方法にもなったと思います。<br/><br/>
              今まで使っていた商品のCSVデータを活用出来たのも導入の決め手となりました。2万点もデータを打ち込むのは大変ですからねぇ。今までの棚卸しにかける人件費、経費を考えたら大幅なコストダウンにもつながっています。
            </p>
          </div>
        </Reveal>
      </div>
      <div style={{display:"flex", justifyContent:"space-around", flexWrap:"wrap"}}>
        <Reveal animation={Animation.FadeIn} delay={500}>
          <div style={{padding:"1rem", display: "flex", flexDirection: "column"}}>
            <img style={{margin:"1rem"}} src={gwasendoImg1} alt="画箋堂"/>
            <img style={{margin:"1rem"}} src={gwasendoImg2} alt="画箋堂"/>
          </div>
        </Reveal>
        <Reveal animation={Animation.FadeIn} style={{padding:"1rem"}}>
          <div className="customer-voice-description">
            <h4>画箋堂</h4>
            <h5>有限会社 画箋堂</h5>
            <p style={{maxWidth:410, textAlign:"left"}}>
              <b>店頭商品4万点…<br/>21人がかりだった棚卸時間を大幅短縮！</b><br/><br/>
              2021年9月の展示会で「バーコードde実棚」のアプリを知り、コストパフォーマンスも非常に良いので今年（同年）9月末の棚卸しで初めて使わせていただきました。<br/>
              因みに当社は現在４店舗あり登録商品数は10万点以上あり、店頭に常時おいている商品数は約4万点あります。<br/>
              アプリを導入するまでの棚卸はエクセルデータで出力した紙に個数を書いていき、全て記入後にその記入した個数をまたエクセルデータに入力するという事をおこなっておりました。<br/>
              またその日は1日閉店し当社のスタッフ21人とメーカーの方に手伝ってもらって丸一日かけて棚卸しを作業をしており大変でした。<br/>
              棚卸作業自体は1日ですが、準備やその後の作業を考えると数日要していました。<br/>
              しかしアプリ導入後は棚卸の作業時間は半分以下になり、後の入力作業などが一切要らないので、棚卸しに関わる総時間は相当大幅な時間が短縮できたと思います。<br/>
              またスタッフも楽になったと言っております。<br/>
              次の棚卸はもっと早くスムーズにできるかと思います。<br/><br/>
              社歴　　　100年以上<br/>
              スタッフ　21名<br/>
              登録SKU　10万点以上<br/>
              店頭SKU　約4万点
            </p>
          </div>
        </Reveal>
      </div>
      <a href="https://apps.apple.com/app/id1509177299/" target="_blank" style={{ color: "white" }}>
        <Button>
          無料ではじめる
        </Button>
      </a>
      <a href="https://tanaoroshi.barpro.co.jp/" target="_blank" style={{ color: "white" }}>
        <Button>
          有料会員になる
        </Button>
      </a>
    </div>
  </div>
)

export default CustomerVoice
